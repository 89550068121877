const hin = {
  bankNames: {
    UNIONPAY: 'UnionPay Transfer',
    BANKPAY: 'Fast Transfer',
    ALIPAY: 'Alipay',
    UNIONPAY_INTERNATIONAL: 'UnionPay Card',
    UNIONPAY_INTERNATIONAL_EURO: 'UnionPay Card EURO',
    QUICKPAY: 'QuickPay',
    BANK_TRANSFER: 'बैंक ट्रांसफर',
    CREDIT_CARD: "Credit Card",
    COD: "Cash On Delivery",
    UPI: "UPI",
    NETBANKING: "Netbanking / UPI / Cards",
    DEBIT_CARD: "Debit Card",
    MASTERCARD: "MasterCard",
    VISA: "Visa",
    JCB: "JCB",
    AMERICAN_EXPRESS: "American Express",
    DISCOVER: "Discover",
    DINERS_CLUB: "Diners Club",
    UPI_PHONE_PE: "UPI - PhonePE",
    UPI_BHIM: "UPI - BHIM",
    UPI_PAY_TM: "UPI - PayTM",
    G_PAY: "G Pay",
    CUP: "CUP Wallet",
    EXPRESS_BANK_TRANSFER: "Express Bank Transfer",
    VIRTUAL_ACCOUNT_IDR: "Virtual Account IDR",
    QR_CODE_THB: "QR Code THB"
  },
  heders: {
    balance: 'बैलेंस',
    ACCOUNT_ID: 'उपयोगकर्ता नाम:'
  },
  preview: {
    CREATE_WALLET: "CREATE WALLET",
    CREATE_WALLET_TIP: "New user? Click here and a new window will open to create your wallet.",
    TOP_UP_WALLET: "TOP UP WALLET",
    TOP_UP_WALLET_TIP: "Click here and a new window will open to add more funds to your wallet.",
    TRANSFER: "TRANSFER FUNDS",
    TRANSFER_TIP: "Click here to send funds to your Trading Account.",
    CHOOSE_OPTIONS: "Please, choose one of the three options to continue:"
  },
  verification: {
    ENTER_VERIFICATION_FIELDS: "Please, enter verification fields:",
    SUBMIT: "SUBMIT"
  },
  buyCoinsTR: {
    PURCHASE_COINS:'Purchase Coins',
    TRY_AGAIN: 'Try again',
    ERROR_OCCURED: 'An error has occurred please try again',
    RESTRICTED: "वर्जित",
    TRANSFER_COMPLETED: 'ਟ੍ਰਾਂਜੈਕਸ਼ਨ ਪੂਰੀ ਹੋਈ',
    BUY_COINS: 'डिपॉज़िट करें',
    PAYMENT_METHOD: 'भुगतान विधि',
    coin: 'सिक्का',
    BUY_NOW: 'अभी डिपॉज़िट करें',
    YUAN:'YUAN',
    CNY: 'CNY',
    USD: 'USD',
    EUR: 'EUR',
    INR: 'INR',
    AMOUNT_TO_PURCHASE: 'राशि',
    AMOUNT_TO_TRANSFER: 'Amount to Transfer',
    AMOUNT: 'Amount',
    name: 'नाम',
    lastName: 'अंतिम नाम',
    bankName: 'बैंक का नाम',
    email: 'ਈਮੇਲ',
    phone: 'ਮੋਬਾਈਲ ਨੰਬਰ',
    mobileNumber: "ਮੋਬਾਈਲ ਨੰਬਰ",
    country: 'ਦੇਸ਼',
    account_no: 'बैंक कार्ड नंबर',
    card_holder_name: 'कार्डधारक का नाम',
    card_type: 'कार्ड के प्रकार',
    expiration_month: 'समाप्ति माह (MM)',
    expiration_year: 'समाप्ति वर्ष',
    cvv: 'CVV',
    ifsc: "IFSC",
    account_name: "Account Holder Name",
    account_number: "Account Number",
    state: "ਰਾਜ",
    city: 'ਸ਼ਹਿਰ',
    street: 'ਗਲੀ',
    chineseName: "Chinese Name",
    chineseId: "ID Number(身份证号码）",
    verifyCode: "Verification code",
    address: "पता",
    pincode: "ਡਾਕ ਕੋਡ",
    postalCode: 'ਡਾਕ ਕੋਡ',
    postalcode: "ਡਾਕ ਕੋਡ",
    postcode: "ਡਾਕ ਕੋਡ",
    FEE: 'शुल्क',
    TOTAL: 'कुल',
    select: 'ਚੁਣੋ',
    redirectNote: 'आपको अब सुरक्षित भुगतान के लिए भेजा जा रहा है',
    PLEASE_WAIT: "कृपया प्रतीक्षा करें",
    paymentProcessing: 'जबकि हम आपका डिपॉज़िट संसाधित कर रहे हैं।.',
    waitingTime: 'इसमें कुछ मिनटों का समय लग सकता है।',
    pleaseWait: 'कृपया प्रतीक्षा करें',
    understand: 'ठीक है, मैं समझ गया',
    THANK_YOU: 'धन्यवाद',
    restrictedMessage: "आपके अकाउंट पर निकासी प्रतिबंधित है, कृपया अधिक जानकारी के लिए अपने मर्चेंट से संपर्क करें",
    YOUR_REQUEST: 'आपका अनुरोध सबमिट हो गया है',
    UPDATE_BALANCE: 'बैलेंस अपडेट करें',

    ERROR: "ਤਰੁੱਟੀ",
    OK: "ਠੀਕ ਹੈ",

    SUCCESS_WITHDRAWAL: "आपकी निकासी संसाधित हो गई है।",
    FAILED_WITHDRAWAL: "आपकी वापसी असफल रही।",
    SUCCESS_WITHDRAWAL_TIP: "अब आप अपने खाते में वापस आ जाएंगे, कृपया प्रतीक्षा करें।",
    FAILED_WITHDRAWAL_TIP: "कृपया प्रतीक्षा करें और आपको वापसी पृष्ठ पर लौटा दिया जाएगा।",

    AMOUNT_RESULT: "आपने सफलतापूर्वक जमा कर दिया __AMOUNT__ __CURRENCY__.",

    ERR: {
      PLEASE_FILL_IN: 'कृपया भरें ',
      noAmount: 'कृपया राशि दर्ज करें',
      minAmount: 'न्यूनतम डिपॉज़िट __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: 'प्रति लेन-देन अधिकतम डिपॉज़िट __AMOUNT_COINS__ __CURRENCY__',
      noNumber: 'ਕਿਰਪਾ ਸਿਰਫ ਨੰਬਰ ਹੀ ਦਾਖਲ ਕਰੋ',
      TRANSACTION_FAILED: 'क्षमा करें, आपका लेन-देन विफल हो गया। {message}',
      minLength: 'न्यूनतम लंबाई __ MINLENGTH __ है',
      maxLength: 'अधिकतम लंबाई __ MAXLENGTH __ है',
      pattern: 'के लिए अमान्य प्रारूप'
    }
  },
  sellCoinsTR: {
    SELL_COINS: 'निकालें',
    YUAN: 'YUAN',
    CNY: 'CNY',
    USD: 'USD',
    EUR: 'EUR',
    INR: 'INR',
    AMOUNT: 'राशि',
    WITHDRAW: 'निकालें',
    buyCoins: 'Purchase Coins',
    branchName: 'Branch Name',
    bankAcountName: 'Name of Account Holder',
    bankProvince: 'Bank Province',
    bankArea: 'Withdrawal Bank Area',
    bankName: 'बैंक का नाम',
    cardNumber: 'बैंक कार्ड नंबर',
    card_holder_name: 'कार्डधारक का नाम',
    card_type: 'कार्ड के प्रकार',
    name: 'नाम',
    lastName: 'अंतिम नाम',
    email: 'ਈਮੇਲ',
    phone: 'ਮੋਬਾਈਲ ਨੰਬਰ',
    country: 'ਦੇਸ਼',
    city: 'ਸ਼ਹਿਰ',
    street: 'ਗਲੀ',
    postalCode: 'ਡਾਕ ਕੋਡ',
    state: 'ਰਾਜ',
    address: "पता",
    FEE: 'शुल्क',
    TOTAL: 'कुल',
    THANK_YOU: 'धन्यवाद',
    YOUR_REQUEST: 'आपका अनुरोध सबमिट हो गया है',
    UPDATE_BALANCE: 'बैलेंस अपडेट करें',
    waitingTime: 'इसमें कुछ मिनटों का समय लग सकता है।',
    pleaseWait: 'कृपया प्रतीक्षा करें',
    understand: 'ठीक है, मैं समझ गया',

    ERROR: "ਤਰੁੱਟੀ",
    OK: "ਠੀਕ ਹੈ",

    SUCCESS_DEPOSIT: "ਤੁਹਾਡੀ ਰਕਮ ਜਮ੍ਹਾਂ ਹੋ ਗਈ ਹੈ।",
    FAILED_DEPOSIT: "ਤੁਹਾਡੀ ਰਕਮ ਜਮ੍ਹਾਂ ਹੋਣ ਵਿੱਚ ਅਸਫਲ ਰਹੀ।",
    SUCCESS_DEPOSIT_TIP: "ਹੁਣ ਤੁਹਾਨੂੰ ਤੁਹਾਡੇ ਖਾਤੇ 'ਤੇ ਵਾਪਸ ਲਿਆਇਆ ਜਾਵੇਗਾ, ਕਿਰਪਾ ਉਡੀਕ ਕਰੋ।",
    FAILED_DEPOSIT_TIP: "ਕਿਰਪਾ ਉਡੀਕ ਕਰੋੇ ਤੁਹਾਨੂੰ ਭੁਗਤਾਨ ਪੰਨੇ 'ਤੇ ਵਾਪਸ ਲਿਆਇਆ ਜਾਵੇਗਾ।",

    ERR: {
      PLEASE_FILL_IN: 'कृपया भरें ',
      toHigh: 'दर्ज की गई राशि आपके बैलेंस राशि से अधिक है',
      noAmount: 'कृपया राशि दर्ज करें',
      minAmount: 'न्यूनतम निकासी __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: 'प्रति लेन-देन अधिकतम निकासी __AMOUNT_COINS__ __CURRENCY__',
      noNumber: 'ਕਿਰਪਾ ਸਿਰਫ ਨੰਬਰ ਹੀ ਦਾਖਲ ਕਰੋ',
      TRANSACTION_FAILED: 'क्षमा करें, आपका लेन-देन विफल हो गया। {message}',
      minLength: 'न्यूनतम लंबाई __ MINLENGTH __ है',
      maxLength: 'अधिकतम लंबाई __ MAXLENGTH __ है',
      pattern: 'के लिए अमान्य प्रारूप'
    }
  },
  footer: {
    tc: 'TRANSFER COINS',
    bc: 'डिपॉज़िट करें',
    sc: 'निकालें'
  },
  loader: {
    loading: 'लोड कर रहे हैं, प्रतीक्षा करें....',
    welcome : 'TradersCoin में आपका स्वागत है',
    textButton: 'BUY COINS',
    previewText: 'CHOOSE YOUR ACTIONS BELOW',
  },

  noAccess: {
    sorry: "हमें खेद है",
    instruction: "आपका सत्र समाप्त हो गया है, कृपया अपने मर्चेंट के माध्यम से फिर से लॉगिन करें।"
  }
};

export default hin;