const vie = {
  bankNames: {
    UNIONPAY: 'UnionPay Transfer',
    BANKPAY: 'Fast Transfer',
    ALIPAY: 'Alipay',
    UNIONPAY_INTERNATIONAL: 'UnionPay Card',
    UNIONPAY_INTERNATIONAL_EURO: 'UnionPay Card EURO',
    QUICKPAY: 'QuickPay',
    BANK_TRANSFER: 'Chuyển khoản ngân hàng',
    CREDIT_CARD: "Credit Card",
    COD: "Cash On Delivery",
    UPI: "UPI",
    NETBANKING: "Netbanking / UPI / Cards",
    DEBIT_CARD: "Debit Card",
    MASTERCARD: "MasterCard",
    VISA: "Visa",
    JCB: "JCB",
    AMERICAN_EXPRESS: "American Express",
    DISCOVER: "Discover",
    DINERS_CLUB: "Diners Club",
    UPI_PHONE_PE: "UPI - PhonePE",
    UPI_BHIM: "UPI - BHIM",
    UPI_PAY_TM: "UPI - PayTM",
    G_PAY: "G Pay",
    CUP: "CUP Wallet",
    EXPRESS_BANK_TRANSFER: "Express Bank Transfer",
    VIRTUAL_ACCOUNT_IDR: "Virtual Account IDR",
    QR_CODE_THB: "QR Code THB"
  },
  heders: {
    balance: 'Số dư',
    ACCOUNT_ID: 'Tên người dùng:'
  },
  preview: {
    CREATE_WALLET: "CREATE WALLET",
    CREATE_WALLET_TIP: "New user? Click here and a new window will open to create your wallet.",
    TOP_UP_WALLET: "TOP UP WALLET",
    TOP_UP_WALLET_TIP: "Click here and a new window will open to add more funds to your wallet.",
    TRANSFER: "TRANSFER FUNDS",
    TRANSFER_TIP: "Click here to send funds to your Trading Account.",
    CHOOSE_OPTIONS: "Please, choose one of the three options to continue:"
  },
  verification: {
    ENTER_VERIFICATION_FIELDS: "Please, enter verification fields:",
    SUBMIT: "SUBMIT"
  },
  buyCoinsTR: {
    TRANSFER_COMPLETED: 'Giao dịch đã hoàn tất',
    PURCHASE_COINS: 'Purchase',
    BUY_COINS: 'Nạp tiền',
    PAYMENT_METHOD: 'Phương thức thanh toán',
    BUY_NOW: 'Nạp tiền ngay',
    YUAN:'YUAN',
    CNY: 'CNY',
    USD: 'USD',
    EUR: 'EUR',
    INR: 'INR',
    AMOUNT_TO_PURCHASE: 'Số tiền mua',
    AMOUNT_TO_TRANSFER: 'Amount to Transfer',
    AMOUNT: 'Amount',
    name: 'Tên',
    lastName: 'Họ',
    bankName: 'Tên ngân hàng',
    email: 'Email',
    phone: 'Số điện thoại di động',
    mobileNumber: "Số điện thoại di động",
    address: "Địa chỉ",
    postcode: "Mã bưu chính",
    postalcode: "Mã bưu chính",
    postalCode: 'Mã bưu chính',
    state: "Bang",
    country: 'Quốc gia',
    account_no: 'Số thẻ ngân hàng',
    card_holder_name: 'Tên chủ thẻ',
    card_type: 'Loại thẻ',
    expiration_month: 'Tháng hết hạn (MM)',
    expiration_year: 'Năm hết hạn',
    cvv: 'CVV',
    ifsc: "IFSC",
    account_name: "Account Holder Name",
    account_number: "Account Number",
    chineseName: "Chinese Name",
    chineseId: "ID Number(身份证号码）",
    verifyCode: "Verification code",
    city: 'Thành phố',
    street: 'Đường',
    pincode: "Mã bưu chính",
    FEE: 'Phí',
    TOTAL: 'Tổng cộng',
    select: 'Chọn',
    TRY_AGAIN: 'Try again',
    ERROR_OCCURED: 'An error has occurred please try again',
    redirectNote: 'Bạn hiện đang được chuyển tiếp để thanh toán bảo mật',
    PLEASE_WAIT: "VUI LÒNG ĐỢI",
    paymentProcessing: 'trong khi chúng tôi xử lý giao dịch nạp tiền của bạn.',
    waitingTime: 'Quá trình này có thể mất vài phút.',
    pleaseWait: 'VUI LÒNG ĐỢI',
    understand: 'Ok tôi hiểu',
    THANK_YOU: 'CẢM ƠN BẠN',
    RESTRICTED: "RESTRICTED",
    restrictedMessage: "Tài khoản của bạn không được rút tiền, vui lòng liên hệ Đại lý của bạn để biết thêm thông tin.",
    YOUR_REQUEST: 'Yêu cầu của bạn đã được gửi',
    UPDATE_BALANCE: 'Cập nhật số dư',

    ERROR: "Lỗi",
    OK: "OK",

    SUCCESS_DEPOSIT: "Tiền nạp của bạn đã được xử lý.",
    FAILED_DEPOSIT: "Tiền nạp của bạn đã không thành công.",
    SUCCESS_DEPOSIT_TIP: "Bạn sẽ được trở lại tài khoản của mình ngay, vui lòng đợi.",
    FAILED_DEPOSIT_TIP: "Vui lòng chờ và bạn sẽ được trở lại trang thanh toán.",
    ERR: {
      PLEASE_FILL_IN: 'Vui lòng nhập vào ',
      noAmount: 'Vui lòng nhập một số tiền',
      minAmount: 'Nạp tiền tối thiểu là __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: 'Nạp tiền tối đa trên giao mỗi giao dịch là __AMOUNT_COINS__ __CURRENCY__',
      noNumber: 'Vui lòng chỉ nhập số',
      TRANSACTION_FAILED: 'Rất tiếc, giao dịch của bạn đã thất bại. {message}',
      minLength: 'Độ dài tối thiểu là __MINLENGTH__',
      maxLength: 'Độ dài tối đa là __MAXLENGTH__',
      pattern: 'Định dạng không hợp lệ'
    }
  },
  sellCoinsTR: {
    SELL_COINS: 'Rút tiền',
    YUAN:'YUAN',
    CNY: 'CNY',
    USD: 'USD',
    EUR: 'EUR',
    INR: 'INR',
    AMOUNT: 'Amount',
    WITHDRAW: 'Rút tiền',
    bankName: 'Tên ngân hàng',
    branchName: 'Branch Name',
    bankAcountName: 'Name of Account Holder',
    cardNumber: 'Số thẻ ngân hàng',
    bankProvince: 'Bank Province',
    bankArea: 'Withdrawal Bank Area',
    name: 'Tên',
    lastName: 'Họ',
    email: 'Email',
    phone: 'Số điện thoại di động',
    country: 'Quốc gia',
    city: 'Thành phố',
    street: 'Đường',
    postalCode: 'Mã bưu chính',
    postalcode: "Mã bưu chính",
    state: 'Bang',
    mobileNumber: "Số điện thoại di động",
    address: "Địa chỉ",
    postcode: "Mã bưu chính",
    pincode: "Mã bưu chính",
    FEE: 'Phí',
    TOTAL: 'Tổng cộng',
    THANK_YOU: 'CẢM ƠN BẠN',
    YOUR_REQUEST: 'Yêu cầu của bạn đã được gửi',
    UPDATE_BALANCE: 'Cập nhật số dư',
    waitingTime: 'Quá trình này có thể mất vài phút.',
    pleaseWait: 'VUI LÒNG ĐỢI',
    understand: 'Ok tôi hiểu',
    select: 'Chọn',

    ERROR: "Lỗi",
    OK: "OK",

    SUCCESS_WITHDRAWAL: "Việc rút tiền của bạn đã được xử lý.",
    FAILED_WITHDRAWAL: "Việc rút tiền của bạn không thành công.",
    SUCCESS_WITHDRAWAL_TIP: "Bây giờ bạn sẽ được trả lại tài khoản của bạn, xin vui lòng chờ.",
    FAILED_WITHDRAWAL_TIP: "Vui lòng đợi và bạn sẽ được đưa trở lại trang rút tiền.",

    AMOUNT_RESULT: "Bạn đã gửi tiền thành công __AMOUNT__ __CURRENCY__.",

    ERR: {
      PLEASE_FILL_IN: 'Vui lòng nhập vào ',
      toHigh: 'Số tiền đã nhập lớn hơn số dư của bạn',
      noAmount: 'Vui lòng nhập một số tiền',
      minAmount: 'Rút tiền tối thiểu là __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: 'Rút tiền tối đa trên giao mỗi giao dịch là __AMOUNT_COINS__ __CURRENCY__ per transaction',
      noNumber: 'Vui lòng chỉ nhập số',
      minLength: 'Độ dài tối thiểu là __MINLENGTH__',
      maxLength: 'Độ dài tối đa là __MAXLENGTH__',
      pattern: 'Định dạng không hợp lệ'
    }
  },
  footer: {
    tc: 'TRANSFER COINS',
    bc: 'Nạp tiền',
    sc: 'Rút tiền'
  },
  loader: {
    loading: 'Đang tải vui lòng chờ...',
    welcome : 'Chào mừng đến TradersCoin',
    textButton: 'BUY COINS',
    previewText: 'CHOOSE YOUR ACTIONS BELOW',
  },

  noAccess: {
    sorry: "CHÚNG TÔI XIN LỖI",
    instruction: "Phiên chơi của bạn đã hết hạn, vui lòng đăng nhập lại thông qua Người bán của bạn."
  }
};

export default vie;