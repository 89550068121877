const ind = {
  bankNames: {
    UNIONPAY: 'UnionPay Transfer',
    BANKPAY: 'Fast Transfer',
    ALIPAY: 'Alipay',
    UNIONPAY_INTERNATIONAL: 'UnionPay Card',
    UNIONPAY_INTERNATIONAL_EURO: 'UnionPay Card EURO',
    QUICKPAY: 'QuickPay',
    BANK_TRANSFER: 'Transfer Bank',
    CREDIT_CARD: "Credit Card",
    COD: "Cash On Delivery",
    UPI: "UPI",
    NETBANKING: "Netbanking / UPI / Cards",
    DEBIT_CARD: "Debit Card",
    MASTERCARD: "MasterCard",
    VISA: "Visa",
    JCB: "JCB",
    AMERICAN_EXPRESS: "American Express",
    DISCOVER: "Discover",
    DINERS_CLUB: "Diners Club",
    UPI_PHONE_PE: "UPI - PhonePE",
    UPI_BHIM: "UPI - BHIM",
    UPI_PAY_TM: "UPI - PayTM",
    G_PAY: "G Pay",
    CUP: "CUP Wallet",
    EXPRESS_BANK_TRANSFER: "Express Bank Transfer",
    VIRTUAL_ACCOUNT_IDR: "Virtual Account IDR",
    QR_CODE_THB: "QR Code THB"
  },
  heders: {
    balance: 'Saldo',
    ACCOUNT_ID: 'Nama Pengguna:'
  },
  preview: {
    CREATE_WALLET: "CREATE WALLET",
    CREATE_WALLET_TIP: "New user? Click here and a new window will open to create your wallet.",
    TOP_UP_WALLET: "TOP UP WALLET",
    TOP_UP_WALLET_TIP: "Click here and a new window will open to add more funds to your wallet.",
    TRANSFER: "TRANSFER FUNDS",
    TRANSFER_TIP: "Click here to send funds to your Trading Account.",
    CHOOSE_OPTIONS: "Please, choose one of the three options to continue:"
  },
  verification: {
    ENTER_VERIFICATION_FIELDS: "Please, enter verification fields:",
    SUBMIT: "SUBMIT"
  },
  buyCoinsTR: {
    TRANSFER_COMPLETED: 'Transaksi selesai!',
    PURCHASE_COINS: 'Setor',
    BUY_COINS: 'Setor',
    PAYMENT_METHOD: 'Metode Pembayaran',
    BUY_NOW: 'Setor Sekarang',
    YUAN:'YUAN',
    CNY: 'CNY',
    USD: 'USD',
    EUR: 'EUR',
    INR: 'INR',
    AMOUNT_TO_PURCHASE: 'Jumlah yang dibeli',
    AMOUNT_TO_TRANSFER: 'Amount to Transfer',
    AMOUNT: 'Amount',
    name: 'Nama ',
    lastName: 'Nama Belakang',
    bankName: 'Nama Bank',
    email: 'Email',
    phone: 'Nomor Handphone',
    mobileNumber: "Nomor Handphone",
    address: "Alamat",
    postcode: "Kode Pos",
    chineseName: "Chinese Name",
    chineseId: "ID Number(身份证号码）",
    verifyCode: "Verification code",
    postalcode: "Kode Pos",
    postalCode: 'Kode Pos',
    state: "Status",
    country: 'Negara',
    account_no: 'Nomor Kartu',
    card_holder_name: 'Nama pemegang kartu',
    card_type: 'Tipe kartu',
    expiration_month: 'Bulan Kedaluwarsa (MM)',
    expiration_year: 'Tahun Kedaluwarsa',
    cvv: 'CVV',
    ifsc: "IFSC",
    account_name: "Account Holder Name",
    account_number: "Account Number",
    city: 'Kota',
    street: 'Jalan',
    pincode: "Kode Pos",
    FEE: 'Biaya',
    TOTAL: 'Jumlah',
    select: 'Pilih',
    TRY_AGAIN: 'Try again',
    ERROR_OCCURED: 'An error has occurred please try again',
    redirectNote: 'anda sedang diarahkan untuk pembayaran yang aman',
    PLEASE_WAIT: "MOHON TUNGGU",
    paymentProcessing: 'sambil kami memproses setoran Anda.',
    waitingTime: 'Ini bisa memakan waktu beberapa menit.',
    pleaseWait: 'Please wait',
    understand: 'Ok saya mengerti',
    THANK_YOU: 'TERIMA KASIH',
    RESTRICTED: "RESTRICTED",
    restrictedMessage: "Akun Anda dilarang melakukan penarikan, silakan hubungi Penjual Anda untuk informasi lebih lanjut",
    YOUR_REQUEST: 'Permintaan Anda telah dikirimkan',
    UPDATE_BALANCE: 'Perbarui Saldo',

    ERROR: "Kesalahan",
    OK: "OK",

    SUCCESS_DEPOSIT: "Deposit Anda telah diproses.",
    FAILED_DEPOSIT: "Deposit Anda gagal.",
    SUCCESS_DEPOSIT_TIP: "Sekarang Anda akan dikembalikan ke akun Anda, mohon tunggu.",
    FAILED_DEPOSIT_TIP: "Mohon tunggu, Anda akan dikembalikan ke halaman pembayaran.",

    AMOUNT_RESULT: "Anda berhasil melakukan setoran __AMOUNT__ __CURRENCY__.",

    ERR: {
      PLEASE_FILL_IN: 'Harap diisi ',
      noAmount: 'Mohon masukkan jumlah',
      minAmount: 'Min. deposit adalah __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: 'Maks. deposit per transaksi adalah __AMOUNT_COINS__ __CURRENCY__',
      noNumber: 'Silakan masukkan angka saja',
      TRANSACTION_FAILED: 'Maaf, transaksi Anda gagal. {message}',
      minLength: 'Panjang minimum adalah __MINLENGTH__',
      maxLength: 'Panjang maksimum adalah __MAXLENGTH__',
      pattern: 'Format yang tidak valid.'
    }
  },
  sellCoinsTR: {
    SELL_COINS: 'Tarik',
    YUAN:'YUAN',
    CNY: 'CNY',
    USD: 'USD',
    EUR: 'EUR',
    INR: 'INR',
    AMOUNT: 'Amount',
    WITHDRAW: 'Tarik',
    bankName: 'Nama Bank',
    branchName: 'Branch Name',
    bankAcountName: 'Name of Account Holder',
    cardNumber: 'Nomor Kartu Bank',
    bankProvince: 'Bank Province',
    bankArea: 'Withdrawal Bank Area',
    name: 'Nama',
    lastName: 'Nama Belakang',
    email: 'Email',
    phone: 'Nomor Handphone',
    country: 'Negara',
    city: 'Kota',
    street: 'Jalan',
    postalCode: 'Kode Pos',
    postalcode: "Kode Pos",
    state: 'Status',
    mobileNumber: "Nomor Handphone",
    address: "Alamat",
    postcode: "Kode Pos",
    pincode: "Kode Pos",
    FEE: 'Biaya',
    TOTAL: 'Jumlah',
    THANK_YOU: 'TERIMA KASIH',
    YOUR_REQUEST: 'Permintaan Anda telah dikirimkan',
    UPDATE_BALANCE: 'Perbarui Saldo',
    waitingTime: 'Ini bisa memakan waktu beberapa menit.',
    pleaseWait: 'MOHON TUNGGU',
    understand: 'Ok saya mengerti',

    ERROR: "Kesalahan",
    OK: "OK",

    SUCCESS_WITHDRAWAL: "Penarikan Anda telah diproses.",
    FAILED_WITHDRAWAL: "Penarikan Anda tidak berhasil.",
    SUCCESS_WITHDRAWAL_TIP: "Anda sekarang akan dikembalikan ke akun Anda, harap tunggu.",
    FAILED_WITHDRAWAL_TIP: "Harap tunggu dan Anda akan dikembalikan ke halaman penarikan.",

    ERR: {
      PLEASE_FILL_IN: 'Harap diisi ',
      toHigh: 'Jumlah yang dimasukkan lebih tinggi daripada saldo Anda',
      noAmount: 'Mohon masukkan jumlah',
      minAmount: 'Min. penarikan adalah __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: 'Maks. penarikan per transaksi adalah __AMOUNT_COINS__ __CURRENCY__',
      noNumber: 'Silakan masukkan angka saja',
      minLength: 'Panjang minimum adalah __MINLENGTH__',
      maxLength: 'Panjang maksimum adalah __MAXLENGTH__',
      pattern: 'Format yang tidak valid.'
    }
  },
  footer: {
    tc: 'TRANSFER COINS',
    bc: 'Setor',
    sc: 'Tarik'
  },
  loader: {
    loading: 'Sedang memuat mohon tunggu...',
    welcome : 'Selamat datang di TradersCoin',
    textButton: 'BUY COINS',
    previewText: 'CHOOSE YOUR ACTIONS BELOW',
  },

  noAccess: {
    sorry: "KAMI MOHON MAAF",
    instruction: "Sesi Anda telah berakhir, silakan masuk kembali melalui Pedagang Anda."
  }
};

export default ind;