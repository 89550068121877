import eng from './eng';
import zho from './zho';
import spa from './spa';
import hin from './hin';
import may from './may';
import ind from './ind';
import vie from './vie';
import tha from './tha';

const Languages = {
  eng: eng,
  zho: zho,
  spa: spa,
  hin: hin,
  may: may,
  ind: ind,
  vie: vie,
  tha: tha
};

export default Languages;
