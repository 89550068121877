const eng = {
  bankNames: {
    UNIONPAY: 'UnionPay Transfer',
    BANKPAY: 'Fast Transfer',
    ALIPAY: 'Alipay',
    UNIONPAY_INTERNATIONAL: 'UnionPay Card',
    UNIONPAY_INTERNATIONAL_EURO: 'UnionPay Card EURO',
    QUICKPAY: 'QuickPay',
    BANK_TRANSFER: 'Bank Transfer',
    CREDIT_CARD: "Credit Card",
    COD: "Cash On Delivery",
    UPI: "UPI",
    NETBANKING: "Netbanking / UPI / Cards",
    DEBIT_CARD: "Debit Card",
    MASTERCARD: "MasterCard",
    VISA: "Visa",
    JCB: "JCB",
    AMERICAN_EXPRESS: "American Express",
    DISCOVER: "Discover",
    DINERS_CLUB: "Diners Club",
    UPI_PHONE_PE: "UPI - PhonePE",
    UPI_PAY_TM: "UPI - PayTM",
    UPI_BHIM: "UPI - BHIM",
    G_PAY: "G Pay",
    CUP: "CUP Wallet",
    EXPRESS_BANK_TRANSFER: "Express Bank Transfer",
    VIRTUAL_ACCOUNT_IDR: "Virtual Account IDR",
    QR_CODE_THB: "QR Code THB"
  },
  heders: {
    balance: 'BALANCE',
    ACCOUNT_ID: 'Username:'
  },
  preview: {
    CREATE_WALLET: "CREATE WALLET",
    CREATE_WALLET_TIP: "New user? Click here and a new window will open to create your wallet.",
    TOP_UP_WALLET: "TOP UP WALLET",
    TOP_UP_WALLET_TIP: "Click here and a new window will open to add more funds to your wallet.",
    TRANSFER: "TRANSFER FUNDS",
    TRANSFER_TIP: "Click here to send funds to your Trading Account.",
    CHOOSE_OPTIONS: "Please, choose one of the three options to continue:"
  },
  verification: {
    ENTER_VERIFICATION_FIELDS: "Please, enter verification fields:",
    SUBMIT: "SUBMIT"
  },
  buyCoinsTR: {
    TRANSFER_COMPLETED: 'Transaction completed!',
    PURCHASE_COINS: 'Purchase',
    BUY_COINS: 'Deposit',
    PAYMENT_METHOD: 'Payment Method',
    BUY_NOW: 'DEPOSIT',
    YUAN:'YUAN',
    CNY: 'CNY',
    USD: 'USD',
    EUR: 'EUR',
    INR: 'INR',
    AMOUNT_TO_PURCHASE: 'Amount to Purchase',
    AMOUNT_TO_TRANSFER: 'Amount to Transfer',
    AMOUNT: 'Amount',
    name: 'Name',
    lastName: 'Last Name',
    bankName: 'Bank Name',
    email: 'Email',
    phone: 'Phone',
    mobileNumber: "Mobile Number",
    address: "Address",
    postcode: "Postcode",
    postalcode: "Pincode / Postal Code",
    postalCode: 'Postal code',
    state: "State",
    ifsc: "IFSC",
    account_name: "Account Holder Name",
    account_number: "Account Number",
    country: 'Country',
    chineseName: "Chinese Name",
    chineseId: "ID Number(身份证号码）",
    verifyCode: "Verification code",
    account_no: 'Card Number',
    card_holder_name: 'Card Holder Name',
    card_type: 'Card Type',
    expiration_month: 'Exp. Month (MM)',
    expiration_year: 'Exp. Year',
    cvv: 'CVV',
    city: 'City',
    street: 'Street',
    pincode: "Pincode",
    FEE: 'Fee',
    TOTAL: 'Total',
    select: 'Select',
    TRY_AGAIN: 'Try again',
    ERROR_OCCURED: 'An error has occurred please try again',
    redirectNote: 'You are now being directed for secure payment',
    PLEASE_WAIT: "PLEASE WAIT",
    paymentProcessing: 'while we process your purchase and prepare your coins.',
    waitingTime: 'This can take up to 5 minutes.',
    pleaseWait: 'Please wait',
    understand: 'Ok, I understand',
    THANK_YOU: 'Thank you',
    RESTRICTED: "RESTRICTED",
    restrictedMessage: "Withdrawals are restricted on your account, please contact your merchant for more information.",
    YOUR_REQUEST: 'Your request has been submitted',
    UPDATE_BALANCE: 'Update Balance',

    ERROR: "Error",
    OK: "OK",

    SUCCESS_DEPOSIT: "Your deposit has been processed.",
    FAILED_DEPOSIT: "Your deposit was unsuccessful.",
    SUCCESS_DEPOSIT_TIP: "You will now be returned to your account, please wait.",
    FAILED_DEPOSIT_TIP: "Please wait and you will be returned to the deposit page.",

    AMOUNT_RESULT: "You successfully deposited __AMOUNT__ __CURRENCY__.",

    ERR: {
      PLEASE_FILL_IN: 'Please fill in ',
      noAmount: 'Please enter an amount',
      minAmount: 'Min. purchase is __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: 'Max. purchase per transaction is __AMOUNT_COINS__ __CURRENCY__',
      noNumber: 'Please, enter only numbers.',
      TRANSACTION_FAILED: 'Sorry, your transaction failed. Details: {message}',
      minLength: 'Min. length is __MINLENGTH__',
      maxLength: 'Max. length is __MAXLENGTH__',
      pattern: 'Invalid format'
    }
  },
  sellCoinsTR: {
    SELL_COINS: 'WITHDRAW',
    YUAN:'YUAN',
    CNY: 'CNY',
    USD: 'USD',
    EUR: 'EUR',
    INR: 'INR',
    AMOUNT: 'Amount',
    WITHDRAW: 'Withdraw',
    bankName: 'Bank Name',
    branchName: 'Branch Name',
    bankAcountName: 'Name of Account Holder',
    cardNumber: 'Bank Card Number',
    bankProvince: 'Bank Province',
    bankArea: 'Withdrawal Bank Area',
    card_holder_name: 'Card Holder Name',
    card_type: 'Card Type',
    name: 'Name',
    lastName: 'Last Name',
    email: 'Email',
    phone: 'Phone',
    country: 'Country',
    city: 'City',
    street: 'Street',
    postalCode: 'Postal code',
    postalcode: "Pincode / Postal Code",
    state: 'State',
    mobileNumber: "Mobile Number",
    address: "Address",
    postcode: "Postcode",
    pincode: "Pincode",
    FEE: 'Fee',
    TOTAL: 'Total',
    THANK_YOU: 'Thank you',
    YOUR_REQUEST: 'Your request has been submitted',
    UPDATE_BALANCE: 'Update Balance',
    waitingTime: 'This can take up to 5 minutes.',
    pleaseWait: 'Please wait',
    understand: 'Ok, I understand',

    ERROR: "Error",
    OK: "OK",
    SUCCESS_WITHDRAWAL: "Your withdrawal has been processed.",
    FAILED_WITHDRAWAL: "Your withdrawal was unsuccessful.",
    SUCCESS_WITHDRAWAL_TIP: "You will now be returned to your account, please wait.",
    FAILED_WITHDRAWAL_TIP: "Please wait and you will be returned to the withdrawal page.",

    ERR: {
      PLEASE_FILL_IN: 'Please fill in ',
      toHigh: 'The amount entered is higher than your balance',
      noAmount: 'Please enter an amount',
      minAmount: 'Min. withdrawal is __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: 'Max. withdrawal is __AMOUNT_COINS__ __CURRENCY__ per transaction',
      noNumber: 'Please, enter only numbers.',
      minLength: 'Min. length is __MINLENGTH__',
      maxLength: 'Max. length is __MAXLENGTH__',
      pattern: 'Invalid format'
    }
  },
  footer: {
    tc: 'TRANSFER COINS',
    bc: 'DEPOSIT',
    sc: 'WITHDRAW'
  },
  loader: {
    loading: 'Loading please wait…',
    welcome : 'WELCOME',
    textButton: 'BUY COINS',
    previewText: 'CHOOSE YOUR ACTIONS BELOW',
  },

  noAccess: {
    sorry: "WE ARE SORRY",
    instruction: "Your session has expired, please log back in through your merchant."
  }
};

export default eng;