const tha = {
  bankNames: {
    UNIONPAY: 'UnionPay Transfer',
    BANKPAY: 'Fast Transfer',
    ALIPAY: 'Alipay',
    UNIONPAY_INTERNATIONAL: 'UnionPay Card',
    UNIONPAY_INTERNATIONAL_EURO: 'UnionPay Card EURO',
    QUICKPAY: 'QuickPay',
    BANK_TRANSFER: 'การโอนผ่านธนาคาร',
    CREDIT_CARD: "Credit Card",
    COD: "Cash On Delivery",
    UPI: "UPI",
    NETBANKING: "Netbanking / UPI / Cards",
    DEBIT_CARD: "Debit Card",
    MASTERCARD: "MasterCard",
    VISA: "Visa",
    JCB: "JCB",
    AMERICAN_EXPRESS: "American Express",
    DISCOVER: "Discover",
    DINERS_CLUB: "Diners Club",
    UPI_PHONE_PE: "UPI - PhonePE",
    UPI_BHIM: "UPI - BHIM",
    UPI_PAY_TM: "UPI - PayTM",
    G_PAY: "G Pay",
    CUP: "CUP Wallet",
    EXPRESS_BANK_TRANSFER: "Express Bank Transfer",
    VIRTUAL_ACCOUNT_IDR: "Virtual Account IDR",
    QR_CODE_THB: "QR Code THB"
  },
  heders: {
    balance: 'ยอดคงเหลือ',
    ACCOUNT_ID: 'ชื่อผู้ใช้:'
  },
  preview: {
    CREATE_WALLET: "CREATE WALLET",
    CREATE_WALLET_TIP: "New user? Click here and a new window will open to create your wallet.",
    TOP_UP_WALLET: "TOP UP WALLET",
    TOP_UP_WALLET_TIP: "Click here and a new window will open to add more funds to your wallet.",
    TRANSFER: "TRANSFER FUNDS",
    TRANSFER_TIP: "Click here to send funds to your Trading Account.",
    CHOOSE_OPTIONS: "Please, choose one of the three options to continue:"
  },
  verification: {
    ENTER_VERIFICATION_FIELDS: "Please, enter verification fields:",
    SUBMIT: "SUBMIT"
  },
  buyCoinsTR: {
    TRANSFER_COMPLETED: 'ทำรายการซื้อสำเร็จ',
    PURCHASE_COINS: 'Purchase',
    BUY_COINS: 'ฝากเงิน',
    PAYMENT_METHOD: 'วิธีการชำระเงิน',
    BUY_NOW: 'ฝากเงินทันที',
    YUAN:'YUAN',
    CNY: 'CNY',
    USD: 'USD',
    EUR: 'EUR',
    INR: 'INR',
    AMOUNT_TO_PURCHASE: 'จำนวนที่ซื้อ',
    AMOUNT_TO_TRANSFER: 'Amount to Transfer',
    AMOUNT: 'Amount',
    name: 'ชื่อ',
    lastName: 'นามสกุล',
    bankName: 'ชื่อธนาคาร',
    email: 'อีเมล',
    phone: 'เบอร์มือถือ',
    mobileNumber: "เบอร์มือถือ",
    address: "ที่อยู่",
    postcode: "รหัสไปรษณีย์",
    postalcode: "รหัสไปรษณีย์",
    postalCode: 'รหัสไปรษณีย์',
    state: "รัฐ",
    country: 'ประเทศ',
    account_no: 'หมายเลขบัตรธนาคาร',
    card_holder_name: 'ชื่อผู้ถือบัตร',
    card_type: 'ประเภทบัตร',
    expiration_month: 'เดือนที่หมดอายุ (MM)',
    expiration_year: 'ปีที่หมดอายุ',
    cvv: 'CVV',
    ifsc: "IFSC",
    account_name: "Account Holder Name",
    account_number: "Account Number",
    city: 'เมือง',
    street: 'ถนน',
    chineseName: "Chinese Name",
    chineseId: "ID Number(身份证号码）",
    verifyCode: "Verification code",
    pincode: "รหัสไปรษณีย์",
    FEE: 'ค่าธรรมเนียม',
    TOTAL: 'ยอดรวม',
    select: 'เลือก',
    TRY_AGAIN: 'Try again',
    ERROR_OCCURED: 'An error has occurred please try again',
    redirectNote: 'คุณจะถูกพาไปยังส่วนการชำระเงินอย่างปลอดภัย',
    PLEASE_WAIT: "โปรดรอ",
    paymentProcessing: 'ในระหว่างที่เรากำลังประมวลผลคำร้องขอของคุณ',
    waitingTime: 'ขั้นตอนนี้อาจใช้เวลาสักครู่',
    pleaseWait: 'โปรดรอ',
    understand: 'ตกลง ฉันเข้าใจ',
    THANK_YOU: 'ขอบคุณ',
    RESTRICTED: "RESTRICTED",
    restrictedMessage: "การฝากถูกจำกัดอยู่สำหรับบัญชีของคุณ กรุณาติดต่อผู้ขายเพื่อทราบข้อมูลเพิ่มเติม",
    YOUR_REQUEST: 'คำขอของคุณถูกส่งไปแล้ว',
    UPDATE_BALANCE: 'อัปเดตยอดคงเหลือ',

    ERROR: "ข้อผิดพลาด",
    OK: "ตกลง",

    SUCCESS_DEPOSIT: "ดำเนินการฝากเงินของคุณเรียบร้อยแล้ว",
    FAILED_DEPOSIT: "ดำเนินการฝากเงินของคุณไม่สำเร็จ",
    SUCCESS_DEPOSIT_TIP: "โปรดรอสักครู่ คุณจะถูกนำกลับไปที่หน้าบัญชีของคุณ",
    FAILED_DEPOSIT_TIP: "โปรดรอสักครู่ คุณจะถูกนำกลับไปที่หน้าการชำระเงิน",

    AMOUNT_RESULT: "คุณฝากเงินสำเร็จแล้ว __AMOUNT__ __CURRENCY__.",

    ERR: {
      PLEASE_FILL_IN: 'กรุณากรอก ',
      noAmount: 'กรุณาป้อนจำนวนเงิน',
      minAmount: 'การฝากต่ำสุดอยู่ที่ __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: 'การฝากสูงสุดต่อธุรกรรมอยู่ที่ __AMOUNT_COINS__ __CURRENCY__',
      noNumber: 'กรุณาป้อนเฉพาะตัวเลข',
      TRANSACTION_FAILED: 'ขออภัย การทำธุรกรรมของคุณล้มเหลว {message}',
      minLength: 'ความยาวขั้นต่ำคือ __MINLENGTH__ for',
      maxLength: 'ความยาวสูงสุดคือ __MAXLENGTH__ for',
      pattern: 'รูปแบบไม่ถูกต้อง'
    }
  },
  sellCoinsTR: {
    SELL_COINS: 'ถอนเงิน',
    YUAN:'YUAN',
    CNY: 'CNY',
    USD: 'USD',
    EUR: 'EUR',
    INR: 'INR',
    AMOUNT: 'Amount',
    WITHDRAW: 'ถอนเงิน',
    bankName: 'ชื่อธนาคาร',
    branchName: 'Branch Name',
    bankAcountName: 'Name of Account Holder',
    cardNumber: 'หมายเลขบัตรธนาคาร',
    bankProvince: 'Bank Province',
    bankArea: 'Withdrawal Bank Area',
    name: 'ชื่อ',
    lastName: 'นามสกุล',
    email: 'อีเมล',
    phone: 'เบอร์มือถือ',
    country: 'ประเทศ',
    city: 'เมือง',
    street: 'ถนน',
    postalCode: 'รหัสไปรษณีย์',
    postalcode: "รหัสไปรษณีย์",
    state: 'รัฐ',
    mobileNumber: "เบอร์มือถือ",
    address: "ที่อยู่",
    postcode: "รหัสไปรษณีย์",
    pincode: "รหัสไปรษณีย์",
    FEE: 'ค่าธรรมเนียม',
    TOTAL: 'ยอดรวม',
    THANK_YOU: 'ขอบคุณ',
    YOUR_REQUEST: 'คำขอของคุณถูกส่งไปแล้ว',
    UPDATE_BALANCE: 'อัปเดตยอดคงเหลือ',
    waitingTime: 'ขั้นตอนนี้อาจใช้เวลาสักครู่',
    pleaseWait: 'โปรดรอ',
    understand: 'ตกลง ฉันเข้าใจ',
    select: 'เลือก',

    ERROR: "ข้อผิดพลาด",
    OK: "ตกลง",

    SUCCESS_WITHDRAWAL: "การถอนเงินของคุณได้รับการดำเนินการแล้ว",
    FAILED_WITHDRAWAL: "การถอนเงินของคุณไม่สำเร็จ",
    SUCCESS_WITHDRAWAL_TIP: "คุณจะกลับสู่บัญชีของคุณทันทีโปรดรอ",
    FAILED_WITHDRAWAL_TIP: "โปรดรอและคุณจะกลับสู่หน้าการถอนเงิน",

    ERR: {
      PLEASE_FILL_IN: 'กรุณากรอก ',
      toHigh: 'จำนวนที่คุณป้อนสูงกว่ายอดคงเหลือของคุณ',
      noAmount: 'กรุณาป้อนจำนวนเงิน',
      minAmount: 'การถอนต่ำสุดอยู่ที่ __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: 'การถอนสูงสุดต่อธุรกรรมอยู่ที่ __AMOUNT_COINS__ __CURRENCY__',
      noNumber: 'กรุณาป้อนเฉพาะตัวเลข',
      minLength: 'ความยาวขั้นต่ำคือ __MINLENGTH__ for',
      maxLength: 'ความยาวสูงสุดคือ __MAXLENGTH__ for',
      pattern: 'รูปแบบไม่ถูกต้อง'
    }
  },
  footer: {
    tc: 'TRANSFER COINS',
    bc: 'ฝากเงิน',
    sc: 'ถอนเงิน'
  },
  loader: {
    loading: 'กำลังโหลด โปรดรอ...',
    welcome : 'ยินดีต้อนรับสู่ TradersCoin',
    textButton: 'BUY COINS',
    previewText: 'CHOOSE YOUR ACTIONS BELOW',
  },

  noAccess: {
    sorry: "เราขออภัย",
    instruction: "เซสชันของคุณหมดอายุแล้ว กรุณาเข้าสู่ระบบผ่านผู้ขายของคุณอีกครั้ง"
  }
};

export default tha;