const zho = {
  bankNames: {
    UNIONPAY: '银联',
    BANKPAY: '个人帐号转公司帐号',
    ALIPAY: '支付宝',
    UNIONPAY_INTERNATIONAL: '银联国际',
    UNIONPAY_INTERNATIONAL_EURO: '银联国际欧元',
    BANK_TRANSFER: '个人帐号转个人帐号',
    QUICKPAY: '快捷',
    CREDIT_CARD: "信用卡",
    COD: "Cash On Delivery",
    UPI: "UPI",
    NETBANKING: "Netbanking / UPI / Cards",
    DEBIT_CARD: "Debit Card",
    MASTERCARD: "MasterCard",
    VISA: "Visa",
    JCB: "JCB",
    AMERICAN_EXPRESS: "American Express",
    DISCOVER: "Discover",
    DINERS_CLUB: "Diners Club",
    UPI_PHONE_PE: "UPI - PhonePE",
    UPI_BHIM: "UPI - BHIM",
    UPI_PAY_TM: "UPI - PayTM",
    G_PAY: "G Pay",
    CUP: "CUP Wallet",
    EXPRESS_BANK_TRANSFER: "Express Bank Transfer",
    VIRTUAL_ACCOUNT_IDR: "Virtual Account IDR",
    QR_CODE_THB: "QR Code THB"
  },
  heders: {
    balance: '账户余额',
    ACCOUNT_ID: 'Username:'
  },
  preview: {
    CREATE_WALLET: "创建钱包",
    CREATE_WALLET_TIP: "新用户？点击这里打开新窗口建立钱包",
    TOP_UP_WALLET: "钱包充值",
    TOP_UP_WALLET_TIP: "点击这里打开新窗口为钱包进行充值",
    TRANSFER: "转账",
    TRANSFER_TIP: "点击这里转账至交易账户",
    CHOOSE_OPTIONS: "请选择下列选择"
  },
  verification: {
    ENTER_VERIFICATION_FIELDS: "请输入验证码",
    SUBMIT: "提交"
  },
  buyCoinsTR: {
    TRANSFER_COMPLETED: '充值成功！',
    PURCHASE_COINS:'购买金币',
    BUY_COINS:'充值',
    TRY_AGAIN: '请从试',
    PAYMENT_METHOD:'选择支付方式',
    ERROR_OCCURED: '错误，请从试',
    BUY_NOW:'确认',
    YUAN:'人民币',
    CNY:'人民币',
    USD: '美金',
    EUR: '欧元',
    INR: 'INR',
    AMOUNT_TO_PURCHASE: '充值额度',
    AMOUNT_TO_TRANSFER: '转账额度',
    AMOUNT: 'Amount',
    name: '名',
    lastName: '姓',
    email: '电子邮箱',
    bankName: '开户银行',
    card_holder_name: '持卡人姓名',
    card_type: '卡的种类',
    phone: '电话',
    country: '国家',
    chineseName: "中文名字",
    chineseId: "身份证号码",
    verifyCode: "验证码",
    account_no: '账号',
    expiration_month: '有效日期 (MM)',
    expiration_year: '有效日期',
    cvv: '安全码',
    ifsc: "IFSC",
    account_name: "Account Holder Name",
    account_number: "Account Number",
    city: '城市',
    street: '地址',
    postalCode: '邮编',
    postalcode: "邮编",
    mobileNumber: "电话",
    address: "地址",
    postcode: "邮编",
    pincode: "邮编",
    state: '地区',
    FEE: '手续费',
    select: '选择',
    redirectNote: '您马上会被跳转到我们的安全支付平台。请稍候',
    PLEASE_WAIT: "请稍候。",
    paymentProcessing: '正在充值，请耐心等待。',
    waitingTime: '请耐心等待。',
    pleaseWait: '请稍候。',
    understand: '明白了',
    TOTAL: '总计',
    THANK_YOU: '感谢，',
    RESTRICTED: "受限制的",
    restrictedMessage: "Withdrawals are restricted on your account, please contact your merchant for more information.",
    YOUR_REQUEST: '您的申请已提交。',
    UPDATE_BALANCE: '刷新余额',

    ERROR: "错误",
    OK: "确定",

    SUCCESS_DEPOSIT: "您的存款已处理。",
    FAILED_DEPOSIT: "存款失败。",
    SUCCESS_DEPOSIT_TIP: "即将返回您的账户，请稍候。",
    FAILED_DEPOSIT_TIP: "请等待返回付款页面。",

    AMOUNT_RESULT: "您成功存入 __AMOUNT__ __CURRENCY__.",

    ERR: {
      PLEASE_FILL_IN: '请输入 ',
      noAmount: '请输入额度',
      minAmount: '最少充值 __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: '每次最多充值 __AMOUNT_COINS__ __CURRENCY__',
      noNumber: '请仅输入数字',
      TRANSACTION_FAILED: '抱歉，您的交易失败。 {message}',
      minLength: '最小长度为 __MINLENGTH__',
      maxLength: '最大长度为 __MAXLENGTH__',
      pattern: '无效的格式'
    }
  },
  sellCoinsTR: {
    SELL_COINS: '提现',
    title: '卖出金币',
    coin: '金币',
    YUAN:'人民币',
    CNY:'人民币',
    USD: '美金',
    INR: 'INR',
    AMOUNT: '额度',
    WITHDRAW: '提现',
    bankName: '开户银行',
    branchName: '支行全名',
    bankAcountName: '持卡人姓名',
    cardNumber: '卡号',
    bankProvince: '银行省市',
    bankArea: '提现银行地区',
    name: '名',
    lastName: '姓',
    email: '电子邮箱',
    phone: '电话',
    country: '国家',
    address: "地址",
    city: '城市',
    street: '地址',
    select: '选择',
    TOTAL: '总计',
    postalCode: '邮编',
    postalcode: '邮编',
    card_holder_name: '持卡人姓名',
    card_type: '卡的种类',
    pincode: "邮编",
    state: '地区',
    FEE: '手续费',

    ERROR: "错误",
    OK: "确定",

    SUCCESS_WITHDRAWAL: "您的提款已被處理。",
    FAILED_WITHDRAWAL: "您的提款失敗。",
    SUCCESS_WITHDRAWAL_TIP: "現在，您將返回到您的帳戶，請稍候。",
    FAILED_WITHDRAWAL_TIP: "請稍候，您將返回到提款頁面。",

    waitingTime: '请耐心等待。',
    pleaseWait: '请稍候。',
    understand: '明白了',

    THANK_YOU: '感谢，',
    YOUR_REQUEST: '您的申请已提交。',
    UPDATE_BALANCE: '刷新余额',
    ERR: {
      PLEASE_FILL_IN: '请输入 ',
      toHigh: '输入的金额高于您的余额',
      noAmount: '请输入额度',
      minAmount: '最少提现 __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: '每次最多提现 __AMOUNT_COINS__ __CURRENCY__',
      noNumber: '请仅输入数字',
      minLength: '最小长度为 __MINLENGTH__',
      maxLength: '最大长度为 __MAXLENGTH__',
      pattern: '无效的格式'
    }
  },
  footer: {
    tc: '转移积分',
    bc: '充值',
    sc: '提现'
  },
  loader: {
    loading: '载入中。 请等待…',
    text: '欢迎使用Tap2Pay支付系统',
    textButton: '提现'
  },
  noAccess: {
    sorry: "很抱歉",
    instruction: "您的会话已过期，请再次通过商家登录。"
  }
};

export default zho;