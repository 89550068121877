const may = {
  bankNames: {
    UNIONPAY: 'UnionPay Transfer',
    BANKPAY: 'Fast Transfer',
    ALIPAY: 'Alipay',
    UNIONPAY_INTERNATIONAL: 'UnionPay Card',
    UNIONPAY_INTERNATIONAL_EURO: 'UnionPay Card EURO',
    QUICKPAY: 'QuickPay',
    BANK_TRANSFER: 'Pindahan Bank',
    CREDIT_CARD: "Credit Card",
    COD: "Cash On Delivery",
    UPI: "UPI",
    NETBANKING: "Netbanking / UPI / Cards",
    DEBIT_CARD: "Debit Card",
    MASTERCARD: "MasterCard",
    VISA: "Visa",
    JCB: "JCB",
    AMERICAN_EXPRESS: "American Express",
    DISCOVER: "Discover",
    DINERS_CLUB: "Diners Club",
    UPI_PHONE_PE: "UPI - PhonePE",
    UPI_BHIM: "UPI - BHIM",
    UPI_PAY_TM: "UPI - PayTM",
    G_PAY: "G Pay",
    CUP: "CUP Wallet",
    EXPRESS_BANK_TRANSFER: "Express Bank Transfer",
    VIRTUAL_ACCOUNT_IDR: "Virtual Account IDR",
    QR_CODE_THB: "QR Code THB"
  },
  heders: {
    balance: 'Baki',
    ACCOUNT_ID: 'Nama Pengguna:'
  },
  preview: {
    CREATE_WALLET: "CREATE WALLET",
    CREATE_WALLET_TIP: "New user? Click here and a new window will open to create your wallet.",
    TOP_UP_WALLET: "TOP UP WALLET",
    TOP_UP_WALLET_TIP: "Click here and a new window will open to add more funds to your wallet.",
    TRANSFER: "TRANSFER FUNDS",
    TRANSFER_TIP: "Click here to send funds to your Trading Account.",
    CHOOSE_OPTIONS: "Please, choose one of the three options to continue:"
  },
  verification: {
    ENTER_VERIFICATION_FIELDS: "Please, enter verification fields:",
    SUBMIT: "SUBMIT"
  },
  buyCoinsTR: {
    TRANSFER_COMPLETED: 'Transaksi selesai',
    PURCHASE_COINS: 'Purchase',
    BUY_COINS: 'Deposit',
    PAYMENT_METHOD: 'Kaedah Bayaran',
    BUY_NOW: 'DEPOSIT',
    YUAN:'YUAN',
    CNY: 'CNY',
    USD: 'USD',
    EUR: 'EUR',
    INR: 'INR',
    AMOUNT_TO_PURCHASE: 'Jumlah untuk dibeli',
    AMOUNT_TO_TRANSFER: 'Amount to Transfer',
    AMOUNT: 'Amount',
    name: 'Nama',
    lastName: 'Nama Keluarga',
    bankName: 'Nama Bank',
    email: 'E-mel',
    phone: 'Nombor Telefon Bimbit',
    mobileNumber: "Nombor Telefon Bimbit",
    address: "Alamat",
    postcode: "Poskod",
    postalcode: "Poskod",
    postalCode: 'Poskod',
    state: "Negeri",
    country: 'Negara',
    chineseName: "Chinese Name",
    chineseId: "ID Number(身份证号码）",
    verifyCode: "Verification code",
    account_no: 'Nombor Kad',
    card_holder_name: 'Pemegang kad nama',
    card_type: 'Jenis kad',
    expiration_month: 'Bulan Luput (MM)',
    expiration_year: 'Tahun Luput',
    cvv: 'CVV',
    ifsc: "IFSC",
    account_name: "Account Holder Name",
    account_number: "Account Number",
    city: 'Bandar',
    street: 'Jalan',
    pincode: "Poskod",
    FEE: 'Fee',
    TOTAL: 'Jumlah',
    select: 'Pilih',
    TRY_AGAIN: 'Try again',
    ERROR_OCCURED: 'An error has occurred please try again',
    redirectNote: 'anda sedang dihala ke halaman pembayaran selamat',
    PLEASE_WAIT: "SILA TUNGGU",
    paymentProcessing: 'Sementara kami memproses deposit anda.',
    waitingTime: 'Ini mungkin mengambil masa beberapa minit.',
    pleaseWait: 'SILA TUNGGU',
    understand: 'Ok, saya faham',
    THANK_YOU: 'TERIMA KASIH',
    RESTRICTED: "RESTRICTED",
    restrictedMessage: "Pengeluaran disekat untuk akaun anda, sila hubungi Pedagang anda untuk maklumat lanjut ",
    YOUR_REQUEST: 'Permintaan telah dihantar',
    UPDATE_BALANCE: 'Kemas Kini Baki',

    ERROR: "Ralat",
    OK: "OK",

    SUCCESS_DEPOSIT: "Deposit anda telah diproses.",
    FAILED_DEPOSIT: "Deposit anda tidak berjaya.",
    SUCCESS_DEPOSIT_TIP: "Anda akan dikembalikan ke akaun anda, sila tunggu.",
    FAILED_DEPOSIT_TIP: "Sila tunggu dan anda akan dibawa ke halaman pembayaran.",

    AMOUNT_RESULT: "Anda berjaya mendepositkan __AMOUNT__ __CURRENCY__.",

    ERR: {
      PLEASE_FILL_IN: 'Sila isikan ',
      noAmount: 'Sila masukkan amaun',
      minAmount: 'Deposit min. ialah __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: 'Deposit maks. setiap transaksi ialah __AMOUNT_COINS__ __CURRENCY__',
      noNumber: 'Sila masukkan nombor sahaja',
      TRANSACTION_FAILED: 'Maaf, transaksi anda telah gagal. {message}',
      minLength: 'Panjang minimum ialah __MINLENGTH__',
      maxLength: 'Panjang maksimum ialah __MAXLENGTH__',
      pattern: 'Format tidak sah.'
    }
  },
  sellCoinsTR: {
    SELL_COINS: 'PENGELUARAN',
    YUAN:'YUAN',
    CNY: 'CNY',
    USD: 'USD',
    EUR: 'EUR',
    INR: 'INR',
    AMOUNT: 'Amount',
    WITHDRAW: 'Pengeluaran',
    bankName: 'Nama Bank',
    branchName: 'Branch Name',
    bankAcountName: 'Name of Account Holder',
    cardNumber: 'Nombor Kad Bank',
    bankProvince: 'Bank Province',
    bankArea: 'Withdrawal Bank Area',
    name: 'Nama',
    lastName: 'Nama Keluarga',
    email: 'E-mel',
    phone: 'Nombor Telefon Bimbit',
    country: 'Negara',
    city: 'Bandar',
    street: 'Jalan',
    postalCode: 'Poskod',
    postalcode: "Poskod",
    state: 'Negeri',
    mobileNumber: "Nombor Telefon Bimbit",
    account_no: 'Nombor Kad',
    card_holder_name: 'Pemegang kad nama',
    card_type: 'Jenis kad',
    expiration_month: 'Bulan Luput',
    expiration_year: 'Tahun Luput',
    address: "Alamat",
    postcode: "Poskod",
    pincode: "Poskod",
    FEE: 'Fee',
    TOTAL: 'Jumlah',
    select: 'Pilih',
    THANK_YOU: 'TERIMA KASIH',
    YOUR_REQUEST: 'Permintaan telah dihantar',
    UPDATE_BALANCE: 'Kemas Kini Baki',
    waitingTime: 'Ini mungkin mengambil masa beberapa minit.',
    pleaseWait: 'SILA TUNGGU',
    understand: 'Ok, saya faham',

    ERROR: "Ralat",
    OK: "OK",

    SUCCESS_WITHDRAWAL: "Pengeluaran anda telah diproses.",
    FAILED_WITHDRAWAL: "Pengunduran anda tidak berjaya.",
    SUCCESS_WITHDRAWAL_TIP: "Anda sekarang akan dikembalikan ke akaun anda, sila tunggu.",
    FAILED_WITHDRAWAL_TIP: "Tunggu dan anda akan dikembalikan ke halaman pengeluaran.",

    ERR: {
      PLEASE_FILL_IN: 'Sila isikan ',
      toHigh: 'Jumlah yang dimasukkan melebihi baki anda.',
      noAmount: 'Sila masukkan amaun',
      minAmount: 'Pengeluaran min. ialah __AMOUNT_COINS__ __CURRENCY__',
      maxAmount: 'Pengeluaran maks. setiap transaksi ialah __AMOUNT_COINS__ __CURRENCY__',
      noNumber: 'Sila masukkan nombor sahaja',
      minLength: 'Panjang minimum ialah __MINLENGTH__',
      maxLength: 'Panjang maksimum ialah __MAXLENGTH__',
      pattern: 'Format tidak sah.'
    }
  },
  footer: {
    tc: 'TRANSFER COINS',
    bc: 'DEPOSIT',
    sc: 'PENGELUARAN'
  },
  loader: {
    loading: 'Memuat sila tunggu...',
    welcome : 'Selamat datang ke TradersCoin',
    textButton: 'BUY COINS',
    previewText: 'CHOOSE YOUR ACTIONS BELOW',
  },

  noAccess: {
    sorry: "MAAF",
    instruction: "Sesi anda telah tamat, sila log masuk semula menerusi Pedagang anda."
  }
};

export default may;