import { SHOW_UI_PARTS } from '../actions/types';

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = true, action) {
  switch(action.type) {
  case SHOW_UI_PARTS:
    return (action.payload);
  default:
    return state;
  }
}